export async function copyToClipboard(text: string): Promise<boolean> {
    if (!navigator.clipboard) {
        console.warn('Clipboard API not available');
        return false;
    }
    try {
        await navigator.clipboard.writeText(text);
        return true;
    } catch (error) {
        console.error('Failed to copy text to clipboard', error);
        return false;
    }
}


export async function queryPermission(permissionName: PermissionName, extraArgs?: Record<string, unknown>): Promise<PermissionState> {
    if (!navigator.permissions) {
        console.warn('Permissions API not available');
        return 'prompt';
    }
    try {
        const permission = await navigator.permissions.query({name: permissionName, ...extraArgs,});
        return permission.state;
    } catch (error) {
        console.error('Failed to query permission', error);
        return 'prompt';
    }
}
