import React, {useState, useEffect} from 'react';
import {removeListener} from '../lib/messages';
import {setExtChatInputListener} from '../lib/extChatInput';

const useExtChatInput = (): [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>] => {
    const [extChatInput, setExtChatInput] = useState<string | undefined>(undefined);

    useEffect(() => {
        const setChatInputFromMessage = (input: string) => {
            setExtChatInput(input)
            return {};
        };
        const extChatInputListener = setExtChatInputListener(setChatInputFromMessage);

        return () => {
            if (extChatInputListener) {
                removeListener(extChatInputListener);
            }
        };
    }, []);

    return [extChatInput, setExtChatInput];
};

export default useExtChatInput;
