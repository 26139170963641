import { useState, useEffect } from 'react';
import { setUserListener, getDemoUser } from '../lib/pumpRoomUser';
import { removeListener } from '../lib/messages';
import { AuthResult } from '../types';

const useUser = (demoUser: boolean): AuthResult | undefined => {
    const [user, setUser] = useState<AuthResult | undefined>(undefined);

    useEffect(() => {
        let userListener: ((event: MessageEvent) => void) | undefined;

        if (demoUser) {
            console.log("Setting up demo User");
            setUser(getDemoUser());
        } else {
            const setUserFromPumpRoom = (pumproomUser: AuthResult) => {
                if (!user) {
                    setUser(pumproomUser);
                }
                return {};
            };
            userListener = setUserListener(setUserFromPumpRoom);
        }

        return () => {
            if (userListener) {
                removeListener(userListener);
            }
        };
    }, [demoUser]);

    return user;
};

export default useUser;
