import {PumpingTask, TaskFile} from "../types/";

export const getTaskFile = (task: PumpingTask, filePath: string): TaskFile | undefined => {
    return task.files.find(file => file.path === filePath);
};

export const updateTaskFile = (task: PumpingTask, filePath: string, content: string): PumpingTask => {
    const updatedFiles = task.files.map((file) =>
        file.path === filePath ? {...file, content: content} : file
    );
    return {...task, files: updatedFiles};
}
