import {useEffect, useState} from 'react';
import {queryPermission} from '../lib/navigatorUtils';
import {Permissions} from '../types';


const usePermissions = (): Permissions => {
    const [permissions, setPermissions] = useState<Permissions>({
        clipboardWrite: false,
    });

    useEffect(() => {
        const checkPermissions = async () => {
            const clipboardWritePermission: PermissionState = await queryPermission('clipboard-write' as PermissionName);

            setPermissions({
                clipboardWrite: clipboardWritePermission === 'granted',
            });
        };

        checkPermissions();
    }, []);

    return permissions;
};

export default usePermissions;
