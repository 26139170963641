import React, {useState, useEffect} from 'react';
import {Box} from '@mui/material';
import useExtChatInput from '../hooks/useExtChatInput';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import {PumpingTask, TaskFile, TaskSubmissionOutput} from '../types';
import {getTaskFile, updateTaskFile} from '../utils';

interface QAChatProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    currentTask: PumpingTask;
    setCurrentTask: React.Dispatch<
        React.SetStateAction<PumpingTask | undefined>
    >;
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    applyTask: (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => Promise<void>;
}

const QAChat: React.FC<QAChatProps> = (
    {
        taskSubmission,
        loadingResult,
        currentTask,
        setCurrentTask,
        selectedFile,
        setSelectedFile,
        applyTask,
    }
) => {
    const [placeholder, setPlaceholder] = useState<string>('');
    const [chatInput, setChatInput] = useState<string>('');
    const [needSendMessage, setNeedSendMessage] = useState(false);

    const [extChatInput, setExtChatInput] = useExtChatInput();

    useEffect(() => {
        // If external chat input received (from PumpRoom)
        if (extChatInput) {
            setChatInput(extChatInput);
            setCurrentTask(updateTaskFile(currentTask, selectedFile.path, extChatInput));
            setNeedSendMessage(true);
            setExtChatInput(undefined);
        }
    }, [extChatInput, setExtChatInput]);

    useEffect(() => {
        // Send message automatically without clicking button
        const sendMessage = async () => {
            if (needSendMessage) {
                setNeedSendMessage(false);
                await handleSendMessage();
            }
        };
        sendMessage().catch(console.error);
    }, [currentTask.files, needSendMessage]);

    useEffect(() => {
        // Process task files
        if (currentTask.files.length > 0) {
            const starterFile = getTaskFile(currentTask, 'prompt.txt') ||
                getTaskFile(currentTask, 'starter.txt');
            if (starterFile) {
                setSelectedFile(starterFile);
                setChatInput(starterFile.content);
            }
            const placeholderFile = getTaskFile(currentTask, 'placeholder.txt')
            if (placeholderFile) {
                setPlaceholder(placeholderFile.content);
            }
        }
    }, [currentTask, setSelectedFile, selectedFile]);

    const handleSendMessage = async () => {
        // Сохраняем chatPrompt в текущем файле только перед отправкой
        if (!chatInput)
            return
        const updatedCurrentTask = updateTaskFile(currentTask, selectedFile.path, chatInput)
        setCurrentTask(updatedCurrentTask);
        await applyTask(updatedCurrentTask, selectedFile.path);
    };

    const messagesLib = {
        teacher: {
            inputLabel: 'Write your message',
            emptyInputError: 'Message cannot be empty',
            resultPlaceholder: 'I’m ready to review',
            chtName: 'DouDou',
            sendButtonLabel: 'Send',
        },
        chat: {
            inputLabel: 'Напиши свой запрос',
            emptyInputError: 'Запрос не может быть пустым',
            resultPlaceholder: 'Чем могу помочь?',
            chtName: 'ДуДу',
            sendButtonLabel: 'Отправить',
        },
    };

    var messages = messagesLib.chat;
    if (currentTask?.framework === 'teacher') {
        messages = messagesLib.teacher;
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2.5}
            py={2}
            height="100vh"
            width="100%"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                width="100%"
            >
                <Box display="flex" alignItems="center" width="100%" gap={2}>
                    <ChatInput
                        label={messages.inputLabel}
                        sendButtonLabel={messages.sendButtonLabel}
                        emptyInputLabel={messages.emptyInputError}
                        placeholder={placeholder}
                        value={chatInput}
                        onChange={setChatInput}
                        onSend={handleSendMessage}
                        minRows={2}
                        maxRows={6}
                    />
                </Box>
            </Box>
            <ChatMessage
                name={messages.chtName}
                avatar={"/doudou-avatar.jpg"}
                content={taskSubmission?.message || messages.resultPlaceholder}
                result={currentTask?.framework === 'teacher' && taskSubmission ? (
                    taskSubmission.status === 'success' ? (
                        'success'
                    ) : taskSubmission.status !== 'success' ? (
                        'fail'
                    ) : null
                ) : null}
                showLoader={loadingResult}
            />
        </Box>
    );
};

export default QAChat;
