import { postMessage, addEventListener} from './windowUtils';
import { isAllowedOrigin } from './messages';
import { SetChatInputMessage } from '../types';

export const postChatInputMessage = (chatInput: string) => {
    const chatInputMessage: SetChatInputMessage = {
        service: "pumproom",
        type: "setChatPrompt",
        payload: { chatPrompt: chatInput },
        parentTargetIDs: ["it-doudou-modal-background"],
    };
    postMessage(chatInputMessage, "*");
}

export const setExtChatInputListener = (
    setChatInput: (chatInput: string) => {}
): (event: MessageEvent) => void => {
    const extChatInputListener = createExtChatInputListener(setChatInput) as EventListener;
    addEventListener("message", extChatInputListener);
    return extChatInputListener
}

const createExtChatInputListener = (
    setChatInput: (chatInput: string) => {}
) => {
    return (event: MessageEvent) => {
        if (!isAllowedOrigin(event)) {
            return;
        }
        const data: SetChatInputMessage | undefined = event?.data;
        if (data?.service !== "pumproom" || data?.type !== "setChatPrompt") {
            return;
        }
        setChatInput(data.payload.chatPrompt);
    };
}
