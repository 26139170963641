import React from 'react';
import ReactMarkdown from 'react-markdown';
import MoodIcon from '@mui/icons-material/Mood';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import {
    Box,
    Avatar,
    Paper,
    Typography,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Loader from './Loader';

interface ChatMessageProps {
    name: string;
    content: string;
    avatar?: string | null;
    result?: 'success' | 'fail' | null;
    showName?: boolean;
    showLoader?: boolean;
}

const ChatMessage: React.FC<ChatMessageProps> = (
    {
        name,
        content,
        avatar = null,
        result = null,
        showName = true,
        showLoader = false,
    }
) => {
    const theme = useTheme();
    return (
        <Box
            display="flex"
            alignItems="center"
            width="100%"
            flexGrow={1}
            overflow="hidden"
            sx={{
                gap: {xs: '9px', sm: '13px'},
            }}
        >
            <Avatar
                alt={name}
                sx={{
                    width: {xs: 44, sm: 82},
                    height: {xs: 44, sm: 82},
                    alignSelf: 'flex-start',
                    border:
                        result === 'success' ? (
                            `3px solid ${theme.palette.success.main}`
                        ) : `0px solid transparent`
                }}
                src={avatar || undefined}
            >{name === '...' ? name : ''}</Avatar>
            <Paper
                variant="outlined"
                sx={{
                    height: '100%',
                    overflowY: 'auto',
                    paddingX: 1,
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    minHeight: 100,
                    width: '100%',
                    padding: {xs: '12px', sm: '24px'},
                }}
            >
                {showLoader ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                    >
                        <Loader
                            sx={{marginTop: 4}}
                        />
                    </Box>
                ) : (
                    <Box
                        sx={{
                            // margin: 0,
                            '& p': {
                                // margin: 0,
                                fontSize: {xs: '12px', sm: '18px'},
                                lineHeight: '1.5',
                                marginBottom: '17px',
                            },
                        }}
                    >
                        {showName &&
                            <Typography variant="h6" style={{display: 'flex', alignItems: 'center', gap: '8px'}}
                                        marginBottom={1}>
                                {name}
                                {
                                    result === 'success' ? (
                                        <MoodIcon fontSize='large' color='success'/>
                                    ) : result === 'fail' ? (
                                        <MoodBadIcon fontSize='large' color='error'/>
                                    ) : null
                                }
                            </Typography>
                        }
                        <Typography component="div" variant="body1">
                            <ReactMarkdown>
                                {content}
                            </ReactMarkdown>
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default ChatMessage;
