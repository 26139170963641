import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import IDE from './IDE';
import QAChat from './QAChat';
import GameChat from './GameChat';
import Quiz from './Quiz';
import ErrorModal from './ErrorModal';
import {loadTaskFromBE, applySubmissionToBE} from '../services/taskService';
import {
    PumpingTask,
    TaskSubmissionOutput,
    IDEState,
    LoadTaskServiceParams,
    TaskFile,
    AppError,
    ProfileInput,
    AuthResult,
} from '../types';
import {themeIDE, themeChat} from '../themes';
import {getTaskQueryParams} from '../lib/queryParams';
import useUserProfile from '../hooks/useUserProfile';
import useUser from '../hooks/useUser';
import useEnvironment from '../hooks/useEnvironment';
import Loader from './Loader';

interface TaskPageProps {
    repoName: string;
    taskName: string;
}

const TaskPage: React.FC<TaskPageProps> = ({repoName, taskName}) => {
    const [taskSubmission, setTaskSubmission] = useState<
        TaskSubmissionOutput | undefined
    >(undefined);
    const [loadingResult, setLoadingResult] = useState<boolean>(false);
    const [error, setError] = useState<AppError | undefined>(undefined);
    const [initialTask, setInitialTask] = useState<PumpingTask | undefined>(
        undefined
    );
    const [currentTask, setCurrentTask] = useState<PumpingTask | undefined>(
        undefined
    );
    const [selectedFile, setSelectedFile] = useState<TaskFile | undefined>(
        undefined
    );

    const taskParams = getTaskQueryParams();

    // Use the custom hook to get the user profile
    const userProfile: ProfileInput | undefined = useUserProfile(
        taskParams.demoUser
    );
    const user: AuthResult | undefined = useUser(
        taskParams.demoUser
    );

    const environment = useEnvironment();

    const onTaskLoadingStart = async () => {
        setLoadingResult(true);
        setError(undefined);
    };

    const onTaskLoadingEnd = async (ideState: IDEState) => {
        setLoadingResult(false);
        if (ideState.currentTask) {
            setCurrentTask(ideState.currentTask);
        }
        if (ideState.initialTask) {
            setInitialTask(ideState.initialTask);
        }
        if (ideState.selectedFile) {
            const selectedFile: TaskFile | undefined =
                ideState.currentTask?.files.find(
                    (file) => file.path === ideState.selectedFile
                );
            setSelectedFile(structuredClone(selectedFile));
        } else if (ideState.currentTask) {
            setSelectedFile(structuredClone(ideState.currentTask.files[0]));
        }
        if (ideState.taskSubmission) {
            setTaskSubmission(ideState.taskSubmission);
        }
    };

    const onTaskLoadingError = async () => {
        setLoadingResult(false);
        setError({type: 'load'});
    };

    const onSubmissionApplyingStart = async () => {
        setLoadingResult(true);
        setError(undefined);
        setTaskSubmission(undefined);
    };

    const onSubmissionApplyingSubmission = async (
        submission: TaskSubmissionOutput
    ) => {
        setTaskSubmission(submission);
    };

    const onSubmissionApplyingEnd = async (
        submission: TaskSubmissionOutput
    ) => {
        setLoadingResult(false);
        setTaskSubmission(submission);
    };

    const onSubmissionApplyingError = async () => {
        setLoadingResult(false);
        setError({type: 'apply'});
    };

    const loadTask = async (abortSignal: AbortSignal) => {
        const loadTaskServiceParams: LoadTaskServiceParams = {
            repoName: repoName,
            taskName: taskName,
            ignoreState: taskParams.ignoreState,
            hangOnLoad: taskParams.hangOnLoad,
        };
        await loadTaskFromBE(
            loadTaskServiceParams,
            environment,
            user,
            userProfile,
            abortSignal,
            onTaskLoadingStart,
            onTaskLoadingEnd,
            onTaskLoadingError
        );
    };

    const applyTaskSubmission = async (
        currentTask: PumpingTask | undefined,
        selectedFilePath: string | undefined
    ) => {
        const applySubmissionServiceParams = {
            errorOnApply: taskParams.errorOnApply,
            hangOnApply: taskParams.hangOnApply,
        };
        await applySubmissionToBE(
            applySubmissionServiceParams,
            currentTask,
            selectedFilePath,
            environment,
            user,
            userProfile,
            onSubmissionApplyingStart,
            onSubmissionApplyingSubmission,
            onSubmissionApplyingEnd,
            onSubmissionApplyingError
        );
    };

    useEffect(() => {
        // Create abort signal to interrupt task loading
        const controller = new AbortController();
        const abortSignal: AbortSignal = controller.signal;

        let taskLoadingTimer: NodeJS.Timeout | undefined;

        if (taskName && repoName) {
            if (taskParams.errorOnLoad) {
                console.warn('Imitating error on task load');
                onTaskLoadingError();
                return;
            }
            // Load task when userProfile is defined
            if (!user && !userProfile) {
                taskLoadingTimer = setTimeout(() => {
                    if (!user && !userProfile) {
                        console.log('Loading task without user profile');
                        loadTask(abortSignal);
                    }
                }, 2000);
            } else {
                loadTask(abortSignal);
            }
        } else {
            onTaskLoadingError();
        }

        return () => {
            // Cancel active routine
            controller.abort();
            if (taskLoadingTimer) {
                clearTimeout(taskLoadingTimer);
            }
        };
    }, [user, userProfile]);

    // Render component based on the current task
    if (currentTask && initialTask && selectedFile) {
        if (currentTask.backend === 'judge0') {
            return (
                <ThemeProvider theme={themeIDE}>
                    <Box className='app' style={{height: '100%'}}>
                        <IDE
                            taskSubmission={taskSubmission}
                            loadingResult={loadingResult}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            initialTask={initialTask}
                            applyTask={applyTaskSubmission}
                        />
                    </Box>
                    {error && (
                        <ErrorModal
                            error={error}
                            onClose={() => setError(undefined)}
                        />
                    )}
                </ThemeProvider>
            );
        } else if (
            currentTask.backend === 'llm' ||
            currentTask.backend === 'gpt'
        ) {
            return (
                <ThemeProvider theme={themeChat}>
                    <Box px={1} className='app' style={{height: '100%'}}>
                        <QAChat
                            taskSubmission={taskSubmission}
                            loadingResult={loadingResult}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            applyTask={applyTaskSubmission}
                        />
                    </Box>
                    {error && (
                        <ErrorModal
                            error={error}
                            onClose={() => setError(undefined)}
                        />
                    )}
                </ThemeProvider>
            );
        } else if (currentTask.backend === 'game') {
            return (
                <ThemeProvider theme={themeChat}>
                    <Box px={1} className='app' style={{height: '100%'}}>
                        <GameChat
                            taskSubmission={taskSubmission}
                            loadingResult={loadingResult}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            applyTask={applyTaskSubmission}
                        />
                    </Box>
                    {error && (
                        <ErrorModal
                            error={error}
                            onClose={() => setError(undefined)}
                        />
                    )}
                </ThemeProvider>
            );
        } else if (currentTask.backend === 'quiz') {
            return (
                <>
                    <Box px={1} className='app' style={{height: '100%'}}>
                        <Quiz currentTask={currentTask}/>
                    </Box>
                    {error && (
                        <ErrorModal
                            error={error}
                            onClose={() => setError(undefined)}
                        />
                    )}
                </>
            );
        } else {
            throw new Error('Unsupported backend: ' + currentTask.backend);
        }
    } else {
        if (!error) {
            return (
                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height='100vh'
                >
                    <Loader/>
                </Box>
            );
        } else {
            return (
                <ErrorModal error={error} onClose={() => setError(undefined)}/>
            );
        }
    }
};

export default TaskPage;
