export const isFullscreenAllowed = (): boolean => {
    return !!(
        document.fullscreenEnabled ||
        (document as any).webkitFullscreenEnabled ||
        (document as any).msFullscreenEnabled
    );
};

export const isFullscreen = (): boolean => {
    return !!(
        document.fullscreenElement ||
        (document as any).webkitFullscreenElement ||
        (document as any).msFullscreenElement
    );
};

export const requestFullscreen = (element: HTMLElement = document.documentElement): Promise<void> => {
    if (element.requestFullscreen) {
        return element.requestFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
        return (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
        return (element as any).msRequestFullscreen();
    }
    return Promise.reject(new Error("Fullscreen API is not supported"));
};

export const exitFullscreen = (): Promise<void> => {
    if (document.exitFullscreen) {
        return document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
        return (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
        return (document as any).msExitFullscreen();
    }
    return Promise.reject(new Error("Fullscreen API is not supported"));
};

export const toggleFullscreen = (element?: HTMLElement): Promise<void> => {
    return isFullscreen() ? exitFullscreen() : requestFullscreen(element);
};

export const addFullscreenChangeListener = (callback: () => void): void => {
    document.addEventListener('fullscreenchange', callback);
    document.addEventListener('webkitfullscreenchange', callback);
    document.addEventListener('msfullscreenchange', callback);
};

export const removeFullscreenChangeListener = (callback: () => void): void => {
    document.removeEventListener('fullscreenchange', callback);
    document.removeEventListener('webkitfullscreenchange', callback);
    document.removeEventListener('msfullscreenchange', callback);
};
