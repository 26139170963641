import React from 'react';
import {Typography, Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {Chip} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import AnsiToHtml from 'ansi-to-html';
import {TaskSubmissionOutput} from '../types';
import Loader from './Loader';

interface ResultPanelProps {
    taskSubmission: TaskSubmissionOutput | undefined;
    loadingResult: boolean;
    pristine: boolean;
}

const ResultPanel: React.FC<ResultPanelProps> = (
    {
        taskSubmission,
        loadingResult,
        pristine,
    }
) => {
    const theme = useTheme();

    const ansiToHtml = new AnsiToHtml({
        fg: '#FFFFFF',
        bg: theme.palette.background.default,
        colors: {
            0: '#FFFFFF', // Белый вместо черного
            1: '#FF3333', // Red
            2: '#33FF33', // Green
            3: '#FFFF33', // Yellow
            4: '#3333FF', // Blue
            5: '#FF33FF', // Magenta
            6: '#33FFFF', // Cyan
            7: '#FFFFFF', // White
            8: '#777777', // Bright Black (Gray)
            9: '#FF6666', // Bright Red
            10: '#66FF66', // Bright Green
            11: '#FFFF66', // Bright Yellow
            12: '#6666FF', // Bright Blue
            13: '#FF66FF', // Bright Magenta
            14: '#66FFFF', // Bright Cyan
            15: '#FFFFFF', // Bright White
        },
    });
    return (
        <Box
            marginTop={0}
            paddingX={2}
            style={{overflow: 'auto'}}
            sx={{
                marginTop: {xs: '0px', md: '8px'},
                paddingLeft: {xs: '0px', md: '8px'},
                paddingRight: {xs: '0px', md: '8px'},
            }}
        >
            <Grid container>
                <Grid>
                    <Typography
                        variant='h6'
                        marginBottom={1.5}
                        style={{marginRight: '30px'}}
                        sx={{
                            fontSize: {xs: '11px', md: '20px'},
                            fontWeight: '400',
                        }}
                    >
                        Результат
                    </Typography>
                </Grid>
                <Grid
                    marginLeft={1}
                    sx={{
                        alignItems: 'center',
                    }}
                >
                    {taskSubmission && taskSubmission.status !== 'running' && (
                        <Chip
                            sx={{
                                display: 'flex',
                                height: '16px',
                                padding: '2px 9px',
                                marginTop: {xs: '0px', md: '10px'},
                                alignItems: 'center',
                            }}
                            label={
                                taskSubmission?.status === 'success'
                                    ? 'Успешно выполнено!'
                                    : taskSubmission?.status === 'internal_error'
                                        ? 'Ошибка сервера'
                                        : 'Ошибка компиляции'
                            }
                            color={
                                taskSubmission?.status === 'success'
                                    ? 'success'
                                    : 'error'
                            }
                            size='small'
                        />
                    )}
                </Grid>
            </Grid>
            {!taskSubmission ? (
                <>
                    {pristine && (
                        <>
                            <Typography
                                variant='body2'
                                component={'div'}
                                marginTop={2}
                                sx={{
                                    marginTop: {xs: '10px', md: '16px'},
                                    color: {xs: '#A8A8A8', md: '#A8A8A8'},
                                    fontWeight: {xs: '700', md: '700'},
                                }}
                            >
                                Запустите код, чтобы увидеть результат.
                            </Typography>
                            <Typography
                                variant='body2'
                                component={'div'}
                                marginTop={2}
                                sx={{
                                    marginTop: {xs: '10px', md: '16px'},
                                    color: {xs: '#A8A8A8', md: '#A8A8A8'},
                                    fontWeight: {xs: '700', md: '700'},
                                }}
                            >
                                Если кнопка запуска неактивна, значит надо
                                сперва написать код
                            </Typography>
                        </>
                    )}
                </>
            ) : (
                <>
                    {taskSubmission.ready && (
                        <Box>
                            {taskSubmission.compile_output && (
                                <Typography
                                    variant='body2'
                                    component={'div'}
                                    marginBottom={1}
                                    style={{
                                        color: '#A8A8A8',
                                    }}
                                >
                                    <strong>Вывод компилятора:</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.compile_output.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: 'Cascadia Code, monospace',
                                            whiteSpace: 'pre-wrap',
                                        }}
                                    />
                                </Typography>
                            )}
                            {taskSubmission.stdout && (
                                <Typography
                                    variant='body2'
                                    component={'pre'}
                                    marginBottom={1}
                                    style={{
                                        color: '#A8A8A8',
                                    }}
                                >
                                    <strong>Вывод:</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.stdout.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: 'Cascadia Code, monospace',
                                            // whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                            {taskSubmission.stderr && (
                                <Typography
                                    variant='body2'
                                    component={'pre'}
                                    marginBottom={1}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.stderr.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: 'monospace',
                                            // whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                        </Box>
                    )}
                </>
            )}

            {loadingResult && !pristine && <Loader sx={{m: 2}}/>}
        </Box>
    );
};

export default ResultPanel;
